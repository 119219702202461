/* eslint-disable react/destructuring-assignment */
import React from 'react'
import Helmet from 'react-helmet'
import { object, string, func } from 'prop-types'
import { connect } from 'react-redux'
import { StaticQuery, navigate, graphql } from 'gatsby'
import { decodeTrademarkEntity, getLastParamOfPath } from '@helpers/string-helper'
import { getRoomPackageUpgrades } from '@helpers/room-configurations'
import { productUrl } from '@helpers/route'
import { checkPLALocation, generateSeeInRoomObjects } from '@helpers/static-pdp'
import { getRegionSkuList, availabilityStockMessage } from '@helpers/product'
import { fetchProductBySku, fetchProductWarehouseAvailability } from '@services/product'
import { getRegionZone, getCurrentLocation } from '@helpers/geo-location'
import { setupAnalytics, analyticsProduct } from '@helpers/google-tag-manager'
import 'url-search-params-polyfill'
import Layout from '../components/layout'
import ProductDetail from '../components/product/ProductDetail'
import ProductLIA from '../components/product/product-lia/product-lia-wrapper'
import '../assets/css/pages/product.sass'

class ProductTemplate extends React.Component {
  constructor() {
    super()
    this.state = {
      product: null,
      see_in_room: null,
      room: false,
      storeNum: null,
      availabilityInfo: {},
      room_package_upgrades: [],
    }
  }

  componentDidMount() {
    const { dispatch, location } = this.props
    const params = new URLSearchParams(location.search)
    const regionIdParam = params.get('region_id')
    checkPLALocation(dispatch, regionIdParam)
    this.setUp()
    if (window.dataLayer) {
      window.dataLayer.push({ event: 'optimize.activate' })
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.search !== this.props.location.search) {
      const { location, dispatch } = this.props
      const params = new URLSearchParams(location.search)
      const regionIdParam = params.get('region_id')
      checkPLALocation(dispatch, regionIdParam)
    }
    if (prevProps['*'] !== this.props['*']) {
      this.setUp()
    }
  }

  setUp = async () => {
    const {
      location: { pathname, search },
    } = this.props
    const sku = getLastParamOfPath()
    if (sku && new RegExp('[0-9]{8}|[0-9]{7}P|[0-9]{7}p').test(sku)) {
      const product = await this.getProductBySku(sku)
      if (product && !pathname.includes(product.route)) {
        const query = new URLSearchParams(search)
        this.setState({
          product,
          storeNum: query && query.get('storeCode'),
        })
      }
    } else {
      // TODO: navigation need to add code to track ga data
      navigate('/404', { replace: true })
    }
  }

  pageAnalytics(product) {
    if (window && product) {
      setupAnalytics({
        pageData: {
          type: 'product',
          title: product.title,
          path: productUrl(product.route),
        },
      })
      // TODO: EE DETAILS ADD """" IN HTML BODY / needed a fix
      window.dataLayer.push({
        event: 'ee_detail',
        ecommerce: { detail: { products: [analyticsProduct(product)] } },
      })
    }
  }

  getProductBySku = async sku => {
    const product = await fetchProductBySku(sku)
    const room_package_upgrades = await getRoomPackageUpgrades(product)
    const rtg_location = getCurrentLocation()
    const {
      location: { pathname },
    } = this.props
    let stockMessage
    if (
      rtg_location &&
      product &&
      (rtg_location.region !== 'OOM' ||
        ((product.delivery_type === 'O' || product.delivery_type === 'U') && rtg_location.region === 'OOM'))
    ) {
      try {
        const warehouseAvailability = await fetchProductWarehouseAvailability(
          sku,
          rtg_location.distribution_index,
          rtg_location.state,
        )
        this.setState({ availabilityInfo: { ...warehouseAvailability } })
        stockMessage = availabilityStockMessage({
          // todo verify that this is region specific
          availableOn: warehouseAvailability?.data?.earliestAvailability,
          product,
          rtg_location,
        })
        this.pageAnalytics({
          ...product,
          warehouseAvailability,
          stockMessage,
        })
      } catch (e) {
        console.error('Error fetching warehouse availability', e)
      }
    }

    if (!pathname.includes(product.route)) {
      // TODO: navigation need to add code to track ga data
      navigate('/404', { replace: true })
    } else {
      this.setState({
        product,
        room: !product.see_in_room,
        room_package_upgrades,
      })
      const { region } = getRegionZone()
      const see_in_room_sku = getRegionSkuList(product.see_in_room, region)
      if (see_in_room_sku && see_in_room_sku.length > 0) {
        const roomProduct = await fetchProductBySku(see_in_room_sku[0].sku)
        const roomData = generateSeeInRoomObjects(roomProduct)
        this.setState({
          see_in_room: roomData,
          room_package_upgrades,
        })
      }
    }
  }

  render() {
    const { pageContext } = this.props
    const { bannerPlacementMiddle, foundationsMattressBanner } = pageContext || null
    const { product, see_in_room, room, storeNum, availabilityInfo, room_package_upgrades = [] } = this.state
    const { region } = getRegionZone()
    const isAvailableInCurrentRegion =
      availabilityInfo?.data?.availabilities?.[0]?.catalog_availability?.[region] || false
    const availabilityDate = isAvailableInCurrentRegion
      ? availabilityInfo?.data?.availabilities?.[0]?.availableOn || ''
      : ''

    const title = product?.seoTitle ?? product?.title

    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Layout {...this.props}>
        {product && (
          <>
            <Helmet
              title={`${title ? decodeTrademarkEntity(title) : 'Product'} - Rooms To Go`}
              link={[
                {
                  rel: 'canonical',
                  href: `${
                    typeof window !== 'undefined' ? window.location.origin : 'https://www.roomstogo.com'
                  }${productUrl(product.route)}`,
                },
              ]}
            >
              <script async src={process.env.GATSBY_KLARNA_API} data-client-id={process.env.GATSBY_KLARNA_ID} />
            </Helmet>
            {product && typeof product !== 'undefined' && !storeNum && product.title && (
              <ProductDetail
                product={product}
                see_in_room={see_in_room}
                room={room}
                bannerPlacementMiddle={bannerPlacementMiddle}
                foundationsMattressBanner={foundationsMattressBanner}
                financeBanners={pageContext?.financingBanners}
                bannerBySku={pageContext?.bannerBySku}
                room_package_upgrades={room_package_upgrades}
                availabilityDate={availabilityDate}
              />
            )}
            {product && typeof product !== 'undefined' && storeNum && product.title && (
              <StaticQuery
                render={data => {
                  const store = data.allStrapiStore.edges.find(loc => loc.node.StoreNumber === Number(storeNum))
                  if (store) {
                    return <ProductLIA product={product} store={store.node} />
                  }
                  return (
                    <ProductDetail
                      product={product}
                      see_in_room={see_in_room}
                      room={room}
                      bannerPlacementMiddle={bannerPlacementMiddle}
                      foundationsMattressBanner={foundationsMattressBanner}
                      financeBanners={pageContext && pageContext.financingBanners}
                      bannerBySku={pageContext?.bannerBySku}
                      room_package_upgrades={room_package_upgrades}
                      availabilityDate={availabilityDate}
                    />
                  )
                }}
                query={graphql`
                  query {
                    allStrapiStore(filter: { strapi_id: { ne: 0 } }) {
                      edges {
                        node {
                          ...StrapiStoreFragment
                        }
                      }
                    }
                  }
                `}
              />
            )}
          </>
        )}
      </Layout>
    )
  }
}

ProductTemplate.propTypes = {
  pageContext: object,
  location: object,
  dispatch: func,
  '*': string,
}

export default connect()(ProductTemplate)
