import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { styled, Stack } from '@mui/material'

import shopIcon from '@assets/images/shopIcon.png'
import RTGLink from '@shared/link'
import { getRegionZone } from '@helpers/geo-location'
import { getRegionSkuList } from '@helpers/product'
import { fetchProductBySku } from '@services/product'
import { generateSeeInRoomObjects } from '@helpers/static-pdp'

const StyledLink = styled(RTGLink)`
  align-items: center;
  color: #333;
  font-size: 20px;
  font-weight: 700;
  margin: 13px 0 0;
  text-decoration: none;
  text-transform: uppercase;
  @media only screen and (max-width: 1129px) {
    font-size: 18px;
    margin: 15px 0 0;
  }
  @media only screen and (max-width: 1052px) {
    font-size: 16px;
  }
  @media only screen and (max-width: 659px) {
    font-size: 12px;
    margin: 0 0 5px 0;
  }

  img {
    height: 32px;
    padding: 0 5px;
    margin-top: -10px;
  }
`

const ShopTheRoom = ({ see_in_room, product, single_item_room }) => {
  // TODO: use react-query
  const [roomData, setRoomData] = useState(see_in_room)
  useEffect(() => {
    const { region } = getRegionZone()
    const roomSkusInRegion = getRegionSkuList(product?.see_in_room, region)
    // Fetch if the data was not already fetched serverside
    if (!see_in_room && roomSkusInRegion?.length > 0) {
      fetchProductBySku(roomSkusInRegion[0].sku)
        .then(roomProduct => setRoomData(generateSeeInRoomObjects(roomProduct)))
        .catch(error => {
          console.error('Error fetching product by sku', error)
        })
    }
  }, [product.see_in_room, see_in_room])

  if (product?.category === 'livingroom' || !roomData || single_item_room) return null
  return (
    <StyledLink
      data-testid="shopTheRoomButton"
      data={{
        slug: roomData.link,
        title: 'Shop the Room and Save',
        category: 'pdp',
        action: 'shop the room and save click',
        label: 'shop-and-save',
      }}
    >
      <img src={shopIcon} alt="shop-icon" className="shop-icon" />
      <span>Shop the Room &#38; Save &gt;</span>
    </StyledLink>
  )
}

ShopTheRoom.propTypes = {
  see_in_room: PropTypes.objectOf(PropTypes.array),
  single_item_room: PropTypes.bool,
  product: PropTypes.shape({
    see_in_room: PropTypes.objectOf(PropTypes.array),
    category: PropTypes.string,
  }),
}

export default ShopTheRoom
